export default {
  "common": {
    "completeGuide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guía completa"])},
    "selectBottomButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar el botón debajo para leer o compartir el contenido."])},
    "learnMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Descubra&nbsp;más!"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartir"])}
  },
  "header": {
    "nav": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
      "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portafolio"])},
      "growthCalculators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculadoras&nbsp;de&nbsp;crecimiento"])},
      "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biblioteca"])},
      "publications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicaciones"])},
      "scientificBrochures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folletos científicos"])},
      "videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vídeos"])},
      "materialsForParents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materiales para papás"])},
      "healthyStools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heces Saludables"])},
      "complementaryFeeding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alimentación complementaria"])},
      "breastfeeding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lactancia"])},
      "easyTracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FácilRastreo"])},
      "aboutFriso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acerca de Friso"])}
    }
  },
  "footer": {
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Material exclusivo para el profesional de la salud. La lactancia materna es el mejor alimento para el sano crecimiento y desarrollo del lactante."])},
    "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Más fuerte por dentro para poder experimentar más juntos!"])},
    "textC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encuéntranos en:"])},
    "textD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["todos los derechos reservados"])},
    "textE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El contenido de esta página web, incluyendo, pero no limitado a nuestras marcas principales FRISO<sup>&reg;</sup> y FRISOLAC<sup>&reg;</sup>, es propiedad intelectual protegida de FrieslandCampina."])}
  },
  "about": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acerca Friso"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso es una marca global producida y empacada en Holanda por FrieslandCampina, una de las empresas líderes en productos lácteos. La leche que contienen nuestros productos Friso proviene de nuestras propias granjas. Ponemos un cuidado especial en nuestras granjas, desde la calidad del suelo hasta el alimento de las vacas. Nuestros granjeros tienen una orgullosa herencia ya que provienen de familias de granjeros con más de 150 años de experiencia."])},
    "subtitleA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Esto es lo que hace a Friso único!"])},
    "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control sobre toda la cadena de producción"])},
    "textC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso utiliza sólo ingredientes de alta calidad y las técnicas de procesamiento más innovativas para crear fórmulas de excelencia. El procesamiento mínimo y los bajos niveles de proteínas modificadas por calor son esenciales para obtener proteínas de la leche de fácil digestión. Al tener el control sobre toda la cadena de producción, desde la alimentación de las vacas, hasta el producto final en cada lata de Friso, garantizamos la calidad de nuestros productos a los profesionales de la salud y a los padres alrededor del mundo."])},
    "subtitleB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Del pasto al vaso"])},
    "imageAUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/about/logo_es.png"])},
    "imageBUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/about/lifecycle_es.png"])},
    "imageCUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/about/from-grass-to-glass_es.png"])}
  },
  "easyTracking": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FácilRastreo"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué es el Sistema de FácilRastreo?"])},
    "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comenzando desde nuestras propias granjas en Holanda, puedes seguir los controles rigurosos en cada uno de los pasos en la producción de la fórmula Friso"])},
    "cardA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestras<br/>Granjas"])},
    "cardB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recolección<br/>de Leche"])},
    "cardC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envasado"])},
    "cardD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control final<br/>de calidad"])},
    "cardE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprobación<br/>de Exportación"])}
  },
  "poop": {
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La buena digestión es importante para el crecimiento y desarrollo de tu hijo. El indicador más visible de la salud digestiva de tu hijo son… ¡sus heces! Así es como puedes ayudar a tu hijo a lograr unas buenas popós."])},
    "colors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colores"])},
    "shapes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formas"])},
    "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buenos"])},
    "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malos"])},
    "goodColorsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colores Buenos"])},
    "goodColorsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estos <b>colores</b> son variaciones normales."])},
    "greenishCoffee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Café Verdoso"])},
    "coffee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Café"])},
    "coffeeYellow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Café Amarillento"])},
    "darkGreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verde Oscuro"])},
    "badColorsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colores Malos"])},
    "badColorsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estos <b>colores</b> pueden indicar signos de sangrado o problemas de digestión en los crios."])},
    "red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rojo Brillante"])},
    "black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negro"])},
    "white": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blanco"])},
    "withRedBars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con Rayas Rojas"])},
    "goodShapesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formas Fáciles de Defecar"])},
    "goodShapesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estas son las <b>formas</b> ideales que se pueden expulsar fácilmente"])},
    "spongy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elote Esponjoso"])},
    "sausage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salchicha Suave"])},
    "badShapesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formas no Adecuadas"])},
    "badShapesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estas <b>formas</b> indican signos de deshidratación, fibra insuficiente, una dieta inadecuada y/o estrés."])},
    "parsley": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salsa"])},
    "parsleyImageName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parsley_es"])},
    "oats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como Avena"])},
    "grape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Racimo de Uvas"])},
    "chicken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuggets de Pollo"])},
    "rabbit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heces de Conejo"])}
  },
  "complementaryFeeding": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alimentación complementaria"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relación entre neurodesarrollo y alimentación"])},
    "imageAUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/complementary-feeding/table-desktop_es.png"])},
    "imageA2xUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/complementary-feeding/table-desktop2x_es.png"])}
  },
  "breatsfeeding": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lactancia"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los médicos recomiendan la lactancia materna exclusiva al menos durante los primeros seis meses de vida"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Organización Mundial de la Salud (OMS) recomienda la lactancia materna exclusiva durante seis meses, la introducción de alimentos apropriados y seguros para la edad a partir de ese momento, además del mantenimiento de la lactancia materna hasta los 2 años o más."])},
    "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esto puede variar de acuerdo con las diversas circunstancias de las mamás: muchas tendrán que regressar a trabajar antes, otras podrían tener algunas más querrán hacer más independientes a sus bebés desde una edad temprana o compartir la responsabilidad de la alimentación con el padre para que también experimenten la conexión."])},
    "textC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La sociedad ejerce presión sobre cómo y cuándo hay que alimentar al recién nacido. En este sentido, nadie puede decirte lo que es mejor para tu bebé, pues sólo tú conocerás mejor que nadie cómo son sus patrones y hábitos de vida. No obstante, es una realidad que la leche materna es el mejor alimento para tu bebé por lo que te aconsejamos intentes amamantarlo al menos durante los primeros seis meses de vida."])}
  },
  "materialsVideos": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vídeos"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar los videos para mostrar temas de interés a los padres de los pacientes."])},
    "videos": {
      "a": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benefícios de la lactosa"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beneficios-de-la-lactosa-consumidores.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-materials/video-preview-b.png"])}
      },
      "b": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Como puedo saber si mi bebé es intolerante a la lactosa?"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["video-lactosa-para-consumidores-pisa.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-materials/video-preview-c.png"])}
      }
    }
  },
  "publications": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicaciones"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar los artículos para saber más acerca de la ciencia detrás de los productos de Friso."])},
    "articles": {
      "a": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disminución de la Aparición de Síntomas Gastrointestinales en Lactantes Chinos Alimentados con una Fórmula Mínimamente Procesada Disponible Comercialmente: Un Estudio de Observación Transversal"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xiao Yang Sheng, Vanitha Buthmanaban, Glenn A.A. van Lieshout y Panam Parikh"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020-sheng-et-al-jnme-disminucion-de-sintomas-gastrointestinales-en-lactantes-alimentados-con-una-formula-minimamente-procesada.pdf"])}
      },
      "b": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Importancia de la Lactosa en la Dieta Humana: Resultados de una Reunión de Consenso Mexicano"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrique Romero-Velarde, Dagoberto Delgado-Franco, Mariana García-Gutiérrez, Carmen Gurrola-Díaz, Alfredo Larrosa-Haro, Ericka Montijo-Barrios, Frits AJ Muskiet, Belinda Vargas-Guerrero y Jan Geurts"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["consenso-de-lactosa-traduccion-documento.pdf"])}
      },
      "c": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Influence of micellar calcium phosphate on in vitro gastric coagulation and digestion of milk proteins in infant formula model systems"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thorn Huppertz y Tim T. Lambers"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["huppertz-2020-influence-of-calcium-phosphate-on-in-vitro-coagulation-and-digestion-of-milk.pdf"])}
      },
      "d": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cómo el procesamiento puede afectar la digestión de proteínas de la leche y los resultados fisiológicos generales: una revisión sistemática"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glenn A. A. van Lieshout, Tim T. Lambers, Marjolijn C. E., Bragt y Kasper A. Hettinga"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lieshout-2019-como-el-procesamiento-puede-afectar-la-digestion-de-proteinas-de-la-leche-y-los-resultados-fisiologicos-generales.pdf"])}
      },
      "e": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infant milk formulas differ regarding their allergenic activity and induction of T-cell and cytokine responses"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["H. Hochwallner, U. Schulmeister, I. Swoboda, M. Focke-Tejkl, R. Reininger, V. Civaj, R. Campana, J. Thalhamer, S. Scheiblhofer, N. Balic, F. Horak, M. Ollert, N. G. Papadopoulos, S. Quirce, Z. Szepfalusi, U. Herz, E. A. F. van Tol y S. Spitzauer & R. Valenta"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hochwallner-2016-infant-milk-formuls-differ-regrding-their-allergenic-activity-and-induction-of-t-cell-and-cytokine-responses.pdf"])}
      }
    }
  },
  "scientificBrochures": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folletos científicos"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elegir los temas de interés para obtener más información sobre la alimentación de los lactantes y niños."])},
    "articles": {
      "a": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fórmulas de<br/>Rutina Friso"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["triptico-frisolac-friso.pdf"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/scientific-brochures/item-a.png"])}
      },
      "b": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistema de Alergias<br/>Frisolac Gold"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sistema-de-alergias-frisolac-gold-movil.pdf"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/scientific-brochures/item-b.png"])}
      },
      "c": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importancia de la<br/>Lactosa en la Dieta Humana"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["importancia-de-la-lactosa-en-la-dieta-humana.pdf"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/scientific-brochures/item-c.png"])}
      },
      "d": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guía para una Buena<br/>alimentación complementaria"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["manual-alimentacion-complementaria.pdf"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/scientific-brochures/item-d.png"])}
      }
    }
  },
  "libraryVideos": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vídeos"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar los videos para mostrar temas de interés a los padres de los pacientes."])},
    "videos": {
      "a": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digestión de la Grasa Lactea"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["digestion-de-la-grasa-lactea.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-a.png"])}
      },
      "b": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Reducir la lactosa? ¡Piénsalo 2 veces!"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reducir-la-lactosa-nuevo-logo-hcp.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-b.png"])}
      },
      "c": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intolerancia a la lactosa"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["intolerancia-a-la-lactosa-nuevo-logo-hcp.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-c.png"])}
      }
    }
  },
  "calculators": {
    "banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculadoras de creciemiento para el niño sano."])},
    "boy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niño"])},
    "girl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niña"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rellena el formulario con los datos del bebé"])},
    "buttonA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcular"])},
    "percentile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentil"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado"])},
    "headCircumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perímetro cefálico"])},
    "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estatura"])},
    "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longitud"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peso"])},
    "weightHeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peso ─ Estatura"])},
    "weightLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peso ─ Longitud"])},
    "dateBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de nacimiento"])},
    "dateMeasure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de medición"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idad"])},
    "mounths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meses"])},
    "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["años"])},
    "ageRangeYears": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["De ", _interpolate(_list(0)), " a ", _interpolate(_list(1)), " años"])}
  },
  "home": {
    "banners": {
      "a": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva Friso<br />Comfort Next"])},
        "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con probióticos y 3 fibras dietéticas."])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/home/slides/1/packshot_es.png"])}
      },
      "b": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculadoras"])},
        "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con el sello de confianza de la OMS, ahora pude calcular los percentiles de crecimiento de los bebés al instante."])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/home/slides/2/device_es.png"])}
      }
    },
    "columns": {
      "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descubra nuestra gama completa de productos y elija la opción adecuada para el bebé."])},
      "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulte los materiales científicos más recientes que hemos desarrollado para usted, para que siempre se ponga al día en materia de innovación y avances científicos."])},
      "textC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayude a los padres, incluso a distancia, compartiendo estos materiales educativos."])}
    },
    "app": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Nuestra nueva aplicación finalmente está aquí para usted!"])},
      "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estamos muy contentos de anunciarle que nuestra nueva App ya está disponible para descargar para teléfonos iPhone o Android!"])},
      "imageAUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/home/app-devices_es.png"])},
      "imageBUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/home/qr-code_es.png"])}
    },
    "contact": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tengas más dudas..."])},
      "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escriba su correo y pronto nos pondermos en contacto con usted."])},
      "inputA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INTRODUZCA CORREO ELECTRÓNICO"])},
      "inputB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESCRIBA AQUÍ SU PREGUNTA"])},
      "buttonA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitar contacto"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Enviado!"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Se ha producido un error!"])},
      "sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviando..."])}
    }
  },
  "login": {
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este sitio web está destinado exclusivamente a los profesionales de la salud."])},
    "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si su acceso ya está concedido, introduzca los datos a continuación.<br/>Si usted es un profesional de la salud y no tiene las credenciales para este sitio web,<br/><u>pregunte a su persona de contacto en PiSA</u>."])},
    "inpUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario"])},
    "inpPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descubrir"])},
    "invalidCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos incorrectos!"])}
  },
  "portfolio": {
    "banner": {
      "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La lactancia materna es el mejor alimento para el sano crecimiento y desarrollo del lactante."])},
      "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando la lactancia materna no es una opción, nuestra gama de productos presenta soluciones para cada bebé, con o sin necesidades especiales."])}
    },
    "allProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los productos"])},
    "specials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Especiales"])},
    "regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rutina"])},
    "notice": {
      "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La lactancia materna proporciona la mejor nutrición para un crecimiento y desarrollo sano de los lactantes. Las madres deben de recibir orientación con respecto a la nutrición materna adecuada para ayudar a mantener un adecuado aporte y leche materna de calidad. La introducción innecesaria de alimentación con fórmula, parcial o completa, o de otros alimentos o bebidas complementarias pueden tener un efecto negativo en la lactancia materna, el cual puede ser irreversible. Las madres deben de consultar a su doctor y considerar las implicaciones sociales y económicas antes de decidir utilizar sustitutos de la leche materna o si tienen dificultar para amamantar. Se deben de seguir cuidadosamente las instrucciones de utilización, preparación y almacenamiento de los sustitutos de la leche materna ya que el uso inapropiado o innecesario pueden ser un riesgo para la salud."])},
      "buttonA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["He leído y lo entiendo"])}
    }
  },
  "product": {
    "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingredientes"])},
    "preparation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparación"])},
      "tableTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabla de alimentación sugerida"])},
      "table": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["<img class=\"table\" src=\"", _interpolate(_list(0)), "\"/><p><strong>1 cucharada nivelada es de aprox. ", _interpolate(_list(1)), " gramos.<br/>El uso de agua sin hervir, taza o recipientes no esterilizados o la preparación incorrecta del producto puede ser perjudicial para su niño.</strong></p>"])},
      "instructions": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrucciones de<br/>preparación y uso"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La salud de su hijo depende de que siga cuidadosamente las instrucciones para la preparación y uso:"])},
        "number1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lávese las manos y los utensilios rigurosamente utilizando agua limpia."])},
        "number2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hierva el agua durante 5 minutos y deje enfriar hasta que quede tibia. En caso de no contar con esta, se podrá utilizar agua puriﬁcada a temperatura ambiente. No se debe calentar usando horno de microondas."])},
        "number3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Justo antes de su uso, enjuague el recipiente y hierva en agua."])},
        "number4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sirva la cantidad indicada de agua en el recipiente."])},
        "number5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use la cuchara que viene dentro de la lata para añadir 1 cucharada nivelada por  cada 30 ml de agua."])},
        "number6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revuelva o agite bien hasta que el polvo se disuelva por completo."])},
        "number7": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Compruebe la temperatura y alimente a su bebé cuando la fórmula ", _interpolate(_list(0)), " esté tibia."])},
        "number8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coloque la cuchara en su lugar original y cierre la tapa."])},
        "number9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilice la fórmula reconstituida dentro de 1 hora, como máximo, después de  su preparación."])},
        "number10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deseche cualquier toma no consumida."])},
        "warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Atención:<br/>Use la fórmula ", _interpolate(_list(0)), " como máximo dentro de una hora después de su preparación."])}
      }
    },
    "nutricionalTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabla Nutricional"])},
    "frisolacGold1": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac Gold 1"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac<sup>®</sup> Gold 1"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proteína de fácil digestión"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De 0 a 6 meses"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Frisolac Gold 1 tiene baja glicación de las proteínas: el procesamiento mínimo limita la glicación de las proteínas, mostrando un efecto positivo en la digestión</li><li>Frisolac Gold 1 tiene niveles bajos de mineralización de la caseína: se sabe que forma partículas de cuajo más suaves en el estómago, que son más fáciles de digerir</li><li>Evidencia científica ha mostrado que los prebióticos 2’-FL (HMO) y GOS estimulan el crecimiento de bacterias benéficas y ayudan a mantener la salud intestinal</li><li>DHA y ARA apoyan el aprendizaje y el desarrollo cognitivo</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sólidos de la leche (Leche descremada, Suero de leche desmineralizado, Lactosa, Concentrado de proteína de suero de leche), Aceite de palma (<i>Elaeis guineensis</i>), Aceite de canola de bajo contenido de ácido erútico (<i>Brassica napus</i>), Aceite de semilla de palma (<i>Elaeis guineensis</i>), Aceite de girasol (<i>Helianthus annuus</i>)), Galacto&#8209;oligosacáridos, Maltodextrina, Citrato de potásico, Carbonato de calcio, Aceite de pescado, Bitartrato de colina, Oligosacárido (2’&#8209;fucosil&#8209;lactosa), Cloruro magnésico, Aceite unicelular (<i>Mortierella alpina</i>), L&#8209;Ascorbato sódico, Citrato sódico, Fosfato cálcico tribásico, Cloruro sódico, Cloruro potásico, Taurina, Sulfato ferroso, Hidróxido de cálcico, Emulsificante (Lecticina de girasol (<i>Helianthus annuus</i>)), Mioinositol, L&#8209;carnitina, DL&#8209;alfa tocoferil acetato, Sulfato de zinc, Uridina 5&#8209;monofosfato disódica, Antioxidante (Palmitato de ascorbilo), Ácido citidina 5&#8209;monofosfato, D&#8209;pantotenato cálcico, Inosina 5&#8209;monofosfato disódica, Ácido adenosina 5&#8209;monofosfato, Nicotinamida, Guanosina 5&#8209;monofosfato disódica, Sulfato cúprico, Acetato de retinilo, Hidrocloruro de tiamina, Hidrocloruro de piridoxina, Beta caroteno, Sulfato de manganeso II, Ácido fólico, Yoduro potásico, Fitomenadiona, Selenito sódico, D&#8209;biotina, Colecalciferol, Cianocobalamina. <b>Contiene: Leche, lactosa y productos derivados del pescado.</b>"])},
      "preparationTableUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/products/preparation-table-2_es.png"])}
    },
    "frisolacGold2": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac Gold 2"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac<sup>®</sup> Gold 2"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proteína de fácil digestión"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De 6 a 12 meses"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Frisolac Gold 2 tiene baja glicación de las proteínas: el procesamiento mínimo limita la glicación de las proteínas, mostrando un efecto positivo en la digestión</li><li>Frisolac Gold 2 tiene niveles bajos de mineralización de la caseína: se sabe que forma partículas de cuajo más suaves en el estómago, que son más fáciles de digerir</li><li>Evidencia científica ha mostrado que los prebióticos 2’-FL (HMO) y GOS estimulan el crecimiento de bacterias benéficas y ayudan a mantener la salud intestinal</li><li>DHA y ARA apoyan el aprendizaje y el desarrollo cognitivo</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sólidos de la leche (Leche descremada, Suero de leche desmineralizado, Lactosa, Concentrado de proteína de suero de leche), Aceite de palma (<i>Elaeis guineensis</i>), Aceite de canola de bajo contenido de ácido erútico (<i>Brassica napus</i>), Aceite de semilla de palma (<i>Elaeis guineensis</i>), Aceite de girasol (<i>Helianthus annuus</i>)), Galacto&#8209;oligosacáridos, Sólidos de jarabe de glucosa, Citrato potásico, Carbonato de calcio, Fosfato cálcico di&#8209;y tribásico, Oligosacárido (2’&#8209;fucosil&#8209;lactosa), Cloruro magnésico, L&#8209;Ascorbato sódico, Aceite de pescado, Citrato sódico, Bitartrato de colina, Cloruro sódico, Cloruro potásico, Aceite unicelular (<i>Mortierella alpina</i>), Taurina, Sulfato ferroso, Hidróxido de cálcico, Emulsionante (Lecticina de girasol (<i>Helianthus annuus</i>)), Mioinositol, Sulfato de zinc, Uridina 5&#8209;monofosfato disódica, L&#8209;carnitina, DL&#8209;alfa tocoferil acetato, Ácido citidina 5&#8209;monofosfato, Nicotinamida, Antioxidante (Palmitato de ascorbilo), Inosina 5&#8209;monofosfato disódica, Ácido adenosina 5&#8209;monofosfato, D&#8209;pantotenato cálcico, Guanosina 5&#8209;monofosfato disódica, Sulfato cúprico, Hidroclururo de piridoxina, Hidrocloruro de tiamina, Sulfato de manganeso II, Acetato de retinilo, Beta caroteno, Ácido fólico, Riboflavina, Yoduro potásico, Fitomenadiona, Selenito sódico, D&#8209;biotina, Colecalciferol, Cianocobalamina. <b>Contiene: Leche, lactosa y productos derivados del pescado.</b>"])},
      "preparationTableUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/products/preparation-table-4_es.png"])}
    },
    "frisolacGold3": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac Gold 3"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac<sup>®</sup> Gold 3"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proteína de fácil digestión"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De 1 a 3 años"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Friso Gold 3 tiene baja glicación de las proteínas: el procesamiento mínimo limita la glicación de las proteínas, mostrando un efecto positivo en la digestión</li><li>Friso Gold 3 tiene niveles bajos de mineralización de la caseína: se sabe que forma partículas de cuajo más suaves en el estómago, que son más fáciles de digerir</li><li>Evidencia científica ha mostrado que los prebióticos 2'-FL (HMO) y GOS estimulan el crecimiento de bacterias benéficas y ayudan a mantener la salud intestinal</li><li>Probiótico: <i>B. lactis HN019</i> apoya un sistema digestivo sano.</li><li>Friso Gold 3 no contiene sacarosa</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sólidos de la leche (Leche descremada, Suero de leche desmineralizado, Lactosa, Concentrado de proteína de suero de leche), Aceite de palma (<i>Elaeis guineensis</i>), Aceite de canola de bajo contenido de ácido erútico (<i>Brassica napus</i>), Aceite de semilla de palma (<i>Elaeis guineensis</i>), Aceite de girasol (<i>Helianthus annuus</i>)), Galacto&#8209;oligosacáridos, Cloruro potásico, Maltodextrina, Fosfato cálcico tribásico, Citrato potásico, Oligosacárido (2’&#8209;fucosil&#8209;lactosa), L&#8209;Ascorbato sódico, Aceite de pescado, Mioinositol, Cloruro de colina, Aceite unicelular (<i>Mortierella alpina</i>), Taurina, Sulfato ferroso, Saborizantes de Vainilla, Emulsionante (Lecitina de girasol (<i>Helianthus annuus</i>)), Antioxidante (Mezcla de tocoferoles concentrado), Uridina 5&#8209;monofosfato disódica, Sulfato de zinc, Palmitato de ascorbilo, Ácido citidina 5&#8209;monofosfato, Bifidobacterium lactis HN019, Inosina 5&#8209;monofosfato disódica, Ácido adenosina 5&#8209;monofosfato, Nicotinamida, DL&#8209;alfa tocoferil acetato, Guanosina 5&#8209;monofosfato disódica, D&#8209;pantotenato cálcico, Sulfato de manganeso II, Sulfato cúprico, Acetato de retinilo, Hidrocloruro de tiamina, Hidrocloruro de piridoxina, Ácido fólico, Fitomenadiona, Selenito sódico, Yoduro potásico, Colecalciferol, D&#8209;biotina, Cianobalamina. <b>Contiene: Leche, lactosa y productos derivados del pescado.</b>"])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<img class=\"table\" src=\"/img/products/preparation-table-6_es.png\"/><p>El uso de agua sin hervir, taza o recipientes no esterilizados o la preparación incorrecta del producto puede ser perjudicial para su niño.</p><img class=\"table\" src=\"/img/products/preparation-table-7_es.png\"/>"])}
    },
    "frisoVom1": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso VOM 1"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso<sup>®</sup> VOM 1"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["description"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["age"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["body"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ingredients"])},
      "preparationTableUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/products/preparation-table-X_es.png"])}
    },
    "frisoVom2": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso VOM 2"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso<sup>®</sup> VOM 2"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["description"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["age"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["body"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ingredients"])},
      "preparationTableUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/products/preparation-table-X_es.png"])}
    },
    "frisolacGoldPrematuros": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac Gold Prematuros"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac<sup>®</sup> Gold Prematuros"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Del nacimiento en adelante, hasta alcanzar la edad o peso a término"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lactantes prematuros y/o de bajo peso al nacer"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Proporciona un alto contenido de proteína, energía, micronutrientes y ácidos grasos poliinsaturados de cadena larga de acuerdo con las recomendaciones de ESPGHAN para lactantes prematuros</li><li>Relación suero/caseína, 80:20 (suero parcialmente hidrolizado y caseína extensamente hidrolizada)</li><li>Triglicéridos de cadena media: apoyan las necesidades de energía</li><li>Prebióticos GOS: beneficiosos para la microbiota intestinal</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sólidos de la leche (Lactosa, Proteína de suero de leche hidrolizada), Aceites vegetales (Aceite de palma (<i>Elaeis guineensis</i>), Aceite de canola de bajo contenido de ácido erúcico (<i>Brassica napus</i>), Aceite de semilla de palma (<i>Elaeis guineensis</i>), Aceite de TCM (<i>Elaeis guineensis</i>), Aceite de girasol (<i>Helianthus annuus</i>)), Maltodextrina, Caseína hidrolizada, Emulsionante (Ésteres mono y diglicéridos de ácido cítrico), Galacto&#8209;oligosacáridos, Fosfato cálcico di&#8209;y tribásico, Aceite de pescado, Aceite unicelular (<i>Mortierella alpina</i>), Carbonato de calcio, Cloruro magnésico, Cloruro sódico, L&#8209;Ascorbato sódico, Mioinositol, Cloruro de colina, Taurina, Sulfato ferroso, DL&#8209;alfa tocoferil acetato, Sulfato de zinc, Nicotinamida, Emulsionante (Lecitina de girasol (<i>Helianthus annuus</i>)), L&#8209;carnitina, Ácido citidina 5&#8209;monofosfato, Palmitato de ascorbilo, D&#8209;pantotenato cálcico, Uridina 5&#8209;monofosfato disódica, Ácido adenosina 5&#8209;monofosfato, Guanosina 5&#8209;monofosfato disódica, Acetato de retinilo, Inosina 5&#8209;monofosfato disódica, Sulfato cúprico, Riboflavina, Hidrocloruro de tiamina, Hidrocloruro de piridoxina, Ácido fólico, Yoduro potásico, Fitomenadiona, Colecalciferol, Selenito sódico, D&#8209;biotina, Sulfato de manganeso II, Cianocobalamina. <b>Contiene: Leche, lactosa y productos derivados del pescado.</b>"])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>La composición de Frisolac<sup>®</sup> Gold Prematuros está basada en un consumo de 150 ml de fórmula preparada por kg de peso por día.</p><img class=\"table\" src=\"/img/products/preparation-table-5_es.png\"/><p><strong>1 cucharada nivelada es de aprox. 5,2 gramos.</strong></p>"])}
    },
    "frisolacGoldPepAc": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac Gold Pep AC"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac<sup>®</sup> Gold Pep AC"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tratamiento dietético de síntomas severos de alergia a la proteína de la leche de vaca"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De 0 a 12 meses"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Hecho a base de proteína extensamente hidrolizada de caseína. 98% ≤ 1.5 Kda</li><li>Libre de péptidos estimulantes del sistema inmune y es tan eficaz como las fórmulas de aminoácidos libres</li><li>No induce actividad alergénica por proliferación de células T ni por liberación de citoquinas proinflamatorias</li><li>DHA y ARA apoyan el aprendizaje y el desarrollo cognitivo</li><li>Libre de lactosa</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sólidos de jarabe de glucosa, Aceites vegetales (Aceite de palma (<i>Elaeis guineensis</i>), Aceite de semilla de palma (<i>Elaeis guineensis</i>), Aceite de canola de bajo contenido de ácido erúcico (<i>Brassica napus</i>), Aceite de girasol (<i>Helianthus annuus</i>)), Caseína hidrolizada, Emulsionante (Ésteres mono y diglicéridos de ácido cítrico), Fosfato cálcico di&#8209;y tribásico, Cloruro potásico, Cloruro magnésico, Carbonato de calcio, Cloruro de colina, L&#8209;cistina, Emulsionante (Almidón octenil succinato sódico), L&#8209;triptófano, Aceite de DHA de microalgas (Crypthecodinium cohnii), Aceite unicelular (<i>Mortierella alpina</i>), Mioinositol, L&#8209;Ascorbato sódico, Taurina, Sulfato ferroso, Tartrato de L&#8209;carnitina, Sulfato de zinc, Emulsionante (Mono&#8209;y diglicéridos de ácidos grasos), D&#8209;pantotenato cálcico, Uridina 5&#8209;monofosfato disódica, DL&#8209;alfa tocoferil acetato, Palmitato de ascorbilo, Antioxidante (Mezcla tocoferoles concentrados), Ácido citidina 5&#8209;monofosfato, Inosina 5&#8209;monofosfato disódica, Ácido adenosina 5&#8209;monofosfato, Nicotinamida, Guanosina 5&#8209;monofosfato disódica, Hidrocloruro de tiamina, Riboflavina, Sulfato cúprico, Hidrocloruro de piridoxina, Acetato de retinilo, Sulfato de manganeso II, Yoduro potásico, Ácido fólico, Fitomenadiona, Selenito sódico, D&#8209;biotina, Colecalciferol, Cianocobalamina."])},
      "preparationTableUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/products/preparation-table-1_es.png"])}
    },
    "frisoPep": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso Pep"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso<sup>®</sup> Pep"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["description"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["age"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["body"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ingredients"])},
      "preparationTableUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/products/preparation-table-X_es.png"])}
    },
    "frisolacGoldComfortAr": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac Gold Comfort AR"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac<sup>®</sup> Gold Comfort AR"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["description"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["age"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["body"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ingredients"])},
      "preparationTableUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/products/preparation-table-1_es.png"])}
    }
  }
}