interface AppStorage {
  portfolioAgreed?: boolean;
  user: {
    token: string;
  };
}

const STORAGE_KEY = "vue_app";

const TOKEN_VALIDITY = 365; // days

function getStorage(): AppStorage | null {
  const data = localStorage.getItem(STORAGE_KEY);
  if (!data) return null;
  return JSON.parse(data) || null;
}

function getStoragePortfolioAgreed(): boolean {
  const obj: AppStorage | null = getStorage();
  if (!obj) return false;
  return obj.portfolioAgreed || false;
}

function setStoragePortfolioAgreed(): void {
  const obj: AppStorage | null = getStorage();
  if (obj) {
    obj.portfolioAgreed = true;
  }
  localStorage.setItem(STORAGE_KEY, JSON.stringify(obj));
}

function getStorageAuthToken(): string | null {
  const obj: AppStorage | null = getStorage();
  if (!obj) return null;
  return obj.user.token;
}

function checkStorageAuthToken(): boolean {
  const token = getStorageAuthToken();
  if (!token) return false;
  const num = Number(atob(token));
  if (TOKEN_VALIDITY * 24 * 60 * 60 * 1000 < Date.now() - num) return false;
  return true;
}

function setStorageAuthToken(): void {
  const data = JSON.stringify({
    user: { token: btoa(Date.now() + "") }
  });
  localStorage.setItem(STORAGE_KEY, data);
}

function removeStorageAuthToken(): void {
  localStorage.removeItem(STORAGE_KEY);
}

export {
  getStorage,
  getStorageAuthToken,
  checkStorageAuthToken,
  setStorageAuthToken,
  removeStorageAuthToken,
  getStoragePortfolioAgreed,
  setStoragePortfolioAgreed
};
