import { createI18n, LocaleMessages, VueMessageType } from "vue-i18n";

export interface SuportedLocale {
  code: string;
  name: string;
  iconPath: string;
}

export const suportedLocales: SuportedLocale[] = [
  // {
  //   code: "en",
  //   name: "English",
  //   iconPath: "/img/flag-circle-united-kingdom.png"
  // },
  // {
  //   code: "ge",
  //   name: "ქართული",
  //   iconPath: "/img/flag-circle-georgia.png"
  // },
  {
    code: "az",
    name: "Azərbaycanca",
    iconPath: "/img/flag-circle-azerbaijan.png"
  },
  // {
  //   code: "es",
  //   name: "Español",
  //   iconPath: "/img/flag-circle-spain.png"
  // }
];

function getBrowserLocale(options = {}) {
  const defaultOptions = { countryCodeOnly: false };

  const opt = { ...defaultOptions, ...options };

  const navigatorLocale =
    navigator.languages !== undefined
      ? navigator.languages[0]
      : navigator.language;

  if (!navigatorLocale) {
    return undefined;
  }

  const trimmedLocale = opt.countryCodeOnly
    ? navigatorLocale.trim().split(/-|_/)[0]
    : navigatorLocale.trim();

  return trimmedLocale;
}

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages(): LocaleMessages<VueMessageType> {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages: LocaleMessages<VueMessageType> = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).default;
    }
  });
  return messages;
}

export const i18n = createI18n({
  legacy: false,
  // locale: getBrowserLocale({ countryCodeOnly: true }),
  locale: "az",
  fallbackLocale: "az",
  messages: loadLocaleMessages()
});

export const availableLocales = i18n.global.availableLocales;
