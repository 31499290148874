export default {
  "common": {
    "completeGuide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete Guide"])},
    "selectBottomButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the button below to read or share the content."])},
    "learnMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn&nbsp;more!"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])}
  },
  "header": {
    "nav": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
      "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
      "growthCalculators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth calculators"])},
      "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library"])},
      "publications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publications"])},
      "scientificBrochures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scientific brochures"])},
      "videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videos"])},
      "materialsForParents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materials for parents"])},
      "healthyStools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Healthy Stools"])},
      "complementaryFeeding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complementary feeding"])},
      "brochures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brochures"])},
      "breastfeeding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breastfeeding"])},
      "easyTracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EasyTracking"])},
      "aboutFriso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Friso"])}
    }
  },
  "footer": {
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclusive material for the health professional. Breastfeeding is the best food for the healthy growth and development of the infant."])},
    "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stronger on the inside so we can experience more together!"])},
    "textC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find us at:"])},
    "textD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all rights reserved"])},
    "textE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The content of this web site, including, but not limited to, our core brands FRISO<sup>&reg;</sup> and FRISOLAC<sup>&reg;</sup>, is the protected intellectual property of FrieslandCampina."])}
  },
  "about": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Friso"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso is a global brand that is produced and packed in the Netherlands by FrieslandCampina, one of the world’s leading dairy companies. The milk in our Friso products comes from our own farms. We take special care on our farms, from the quality of our soil to the feeding of cows. Our farmers come from proud heritage farming families with over 150 years of dairy experience."])},
    "subtitleA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is what makes Friso unique!"])},
    "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control over the entire chain of production"])},
    "textC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso uses only top quality ingredients and the most innovative manufacturing techniques to create ﬁrst-rate formulas. Miminal processing and low levels of heat-induced protein modifications are essential for delivering high quality and easy to digest milk proteins. By having control over the entire chain of production, from feeding the cows to the recipe inside every Friso tin we assureour products both to healthcare professionals and the quality of mothers worldwide."])},
    "subtitleB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From grass to glass"])},
    "imageAUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/about/logo_en.png"])},
    "imageBUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/about/lifecycle_en.png"])},
    "imageCUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/about/from-grass-to-glass_en.png"])}
  },
  "easyTracking": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EasyTracking"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is the EasyTrack System?"])},
    "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starting from our own farms in the Netherlands, you can follow the rigorous controls at every step in the production of the Friso formula."])},
    "cardA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our<br/>Farms"])},
    "cardB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milk<br/>Collection"])},
    "cardC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packaging"])},
    "cardD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final quality<br/>control"])},
    "cardE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export<br/>Approval"])}
  },
  "poop": {
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good digestion is important for your child's growth and development. The most visible indicator of your child's digestive health is... his or her stool! Here's how you can help your child achieve good poops."])},
    "colors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colors"])},
    "shapes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shapes"])},
    "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good"])},
    "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad"])},
    "goodColorsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good Colors"])},
    "goodColorsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These <b>colors</b> are normal variations."])},
    "greenishCoffee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greenish Coffee"])},
    "coffee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coffee"])},
    "coffeeYellow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yellow Coffee"])},
    "darkGreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dark Green"])},
    "badColorsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad Colors"])},
    "badColorsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These <b>colors</b> may indicate signs of bleeding or digestion problems in infants."])},
    "red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Red"])},
    "black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Black"])},
    "white": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White"])},
    "withRedBars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With Red Stripes"])},
    "goodShapesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good Shapes"])},
    "goodShapesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These are the ideal <b>shapes</b> that can be easily expelled."])},
    "spongy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spongy Corn"])},
    "sausage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soft Sausage"])},
    "badShapesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inadequate Shapes"])},
    "badShapesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These <b>shapes</b> indicate signs of dehydration, insufficient fiber, inadequate diet and/or stress."])},
    "parsley": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parsley"])},
    "parsleyImageName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parsley_en"])},
    "oats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oats"])},
    "grape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grapes"])},
    "chicken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chicken Nuggets"])},
    "rabbit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rabbit Stool"])}
  },
  "complementaryFeeding": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complementary feeding"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relationship between neurodevelopment and nutrition"])},
    "imageAUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/complementary-feeding/table-desktop_en.png"])},
    "imageA2xUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/complementary-feeding/table-desktop2x_en.png"])}
  },
  "breatsfeeding": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breastfeeding"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctors recommend exclusive breastfeeding for at least the first six months of life."])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The World Health Organization (WHO) recommends exclusive breastfeeding for six months, the introduction of age-appropriate and safe foods thereafter, and continued breastfeeding until 2 years of age or beyond."])},
    "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This may vary according to the different circumstances of the moms: many will have to return to work earlier, others may have some more will want to make their babies more independent from an early age or share the responsibility of feeding with the father so that they also experience the connection."])},
    "textC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Society puts pressure on how and when to feed your newborn. In this sense, no one can tell you what is best for your baby, as only you will know better than anyone else how their life patterns and habits are. However, it is a fact that breast milk is the best food for your baby, so we advise you to try to breastfeed your baby for at least the first six months of life."])}
  },
  "materialsVideos": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videos"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select videos to show topics of interest to parents of patients."])},
    "videos": {
      "a": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benefits of lactose"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beneficios-de-la-lactosa-consumidores.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-materials/video-preview-b.png"])}
      },
      "b": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How do I know if my baby is lactose intolerant?"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["video-lactosa-para-consumidores-pisa.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-materials/video-preview-c.png"])}
      }
    }
  },
  "publications": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publications"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select articles to learn more about the science behind Friso products."])},
    "articles": {
      "a": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decreased Occurrence of Gastrointestinal Symptoms in Chinese Infants Fed a Commercially Available Minimally Processed Formula: A Cross-sectional Observational Study"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xiao Yang Sheng, Vanitha Buthmanaban, Glenn A.A. van Lieshout and Panam Parikh"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020-sheng-et-al-jnme-disminucion-de-sintomas-gastrointestinales-en-lactantes-alimentados-con-una-formula-minimamente-procesada.pdf"])}
      },
      "b": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Importance of Lactose in the Human Diet: Results of a Mexican Consensus Meeting"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrique Romero-Velarde, Dagoberto Delgado-Franco, Mariana García-Gutiérrez, Carmen Gurrola-Díaz, Alfredo Larrosa-Haro, Ericka Montijo-Barrios, Frits AJ Muskiet, Belinda Vargas-Guerrero and Jan Geurts"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["consenso-de-lactosa-traduccion-documento.pdf"])}
      },
      "c": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Influence of micellar calcium phosphate on in vitro gastric coagulation and digestion of milk proteins in infant formula model systems"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thorn Huppertz and Tim T. Lambers"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["huppertz-2020-influence-of-calcium-phosphate-on-in-vitro-coagulation-and-digestion-of-milk.pdf"])}
      },
      "d": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How processing can affect milk protein digestion and overall physiological outcomes: a systematic review"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glenn A. A. van Lieshout, Tim T. Lambers, Marjolijn C. E., Bragt and Kasper A. Hettinga"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lieshout-2019-como-el-procesamiento-puede-afectar-la-digestion-de-proteinas-de-la-leche-y-los-resultados-fisiologicos-generales.pdf"])}
      },
      "e": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infant milk formulas differ regarding their allergenic activity and induction of T-cell and cytokine responses"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["H. Hochwallner, U. Schulmeister, I. Swoboda, M. Focke-Tejkl, R. Reininger, V. Civaj, R. Campana, J. Thalhamer, S. Scheiblhofer, N. Balic, F. Horak, M. Ollert, N. G. Papadopoulos, S. Quirce, Z. Szepfalusi, U. Herz, E. A. F. van Tol and S. Spitzauer & R. Valenta"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hochwallner-2016-infant-milk-formuls-differ-regrding-their-allergenic-activity-and-induction-of-t-cell-and-cytokine-responses.pdf"])}
      },
      "f": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feeding the Late and Moderately Preterm Infant: A Position Paper of the European Society for Paediatric Gastroenterology, Hepatology and Nutrition Committee on Nutrition"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alexandre Lapillonne, Jiri Bronsky, Cristina Campoy, Nicholas Embleton, Mary Fewtrell, Nataša Fidler Mis, Konstantinos Gerasimidis, Iva Hojsak, Jessie Hulst, Flavia Indrio, Christian Molgaard, Sissel Jennifer Moltu, Elvira Verduci and Magnus Domellöf"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["feeding-the-late-and-moderately-preterm-infant-lapillone-2019_en.pdf"])}
      },
      "g": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strategies and Future Opportunities for the Prevention, Diagnosis, and Management of Cow Milk Allergy"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benjamin Zepeda-Ortega, Anne Goh, Paraskevi Xepapadaki, Aline Sprikkelman, Nicolaos Nicolaou, Rosa Elena Huerta Hernandez, Amir Hamzah Abdul Latiff, Miu Ting Yat, Mohamed Diab, Bakr Al Hussaini, Budi Setiabudiawan, Urszula Kudla, R. J. Joost van Neerven, Leilani Muhardi and John O. Warner"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["strategies-and-future-opportunities-for-the-prevention-diagnosis-and-management-of-cow-milk-allergy_en.pdf"])}
      }
    }
  },
  "scientificBrochures": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scientific brochures"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose topics of interest to learn more about infant and child feeding."])},
    "articles": {
      "a": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milk formula with different glycation profile influences gastrointestinal outcomes in Chinese infants"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["milk-formula-with-different-glycation-profile-influences-gi-outcomes-in-chinese-infants_en.pdf"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/scientific-brochures/item-a.png"])}
      },
      "b": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Brochure 2020"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["product-brochure-2020-azerbaijan_en.pdf"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/scientific-brochures/item-b.png"])}
      }
    }
  },
  "brochures": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brochures"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose topics of interest."])},
    "articles": {
      "a": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patients' guide for the management of Cows' Milk Protein Allergy (CMPA)"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["booklet-cows-milk-allergy_en.pdf"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/brochures/item-a.png"])}
      },
      "b": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patients' guide for the management of Functional Gastrointestinal Disorders (FGIDs)"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["booklet-functional-gastrointestinal-disorders_en.pdf"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/brochures/item-b.png"])}
      }
    }
  },
  "libraryVideos": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videos"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select videos to show topics of interest to parents of patients."])},
    "videos": {
      "a": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digestion of milk fat"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["digestion-de-la-grasa-lactea.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-a.png"])}
      },
      "b": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reduce lactose? Think twice!"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reducir-la-lactosa-nuevo-logo-hcp.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-b.png"])}
      },
      "c": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lactose intolerance"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["intolerancia-a-la-lactosa-nuevo-logo-hcp.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-c.png"])}
      },
      "d": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friesland Campina - Farmer Story"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["frieslandcampina-farmer-story_en.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-d.png"])}
      },
      "e": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strategies and future opportunities for the prevention, diagnosis, and management of cow milk allergy"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["frieslandcampina-publication-series-zepeda-et-al-strategies-and-future-opportunities-for-the-prevention-diagnosis-and-management-of-cow-milk-allergy_en.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-e.png"])}
      },
      "f": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single vs. Double Processing"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["glycation-single-versus-double-processing_en.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-f.png"])}
      },
      "g": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lactose - Part I"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lactose-part-i_en.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-g.png"])}
      },
      "h": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lactose - Part II"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lactose-part-ii_en.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-h.png"])}
      }
    }
  },
  "calculators": {
    "banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth calculators for the healthy child."])},
    "boy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boy"])},
    "girl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Girl"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the form with the baby's details"])},
    "buttonA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculate"])},
    "percentile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentile"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result"])},
    "headCircumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Head circumference"])},
    "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height"])},
    "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Length"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])},
    "weightHeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight ─ Height"])},
    "weightLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight ─ Length"])},
    "dateBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth date"])},
    "dateMeasure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measure date"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
    "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["months"])},
    "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["years"])},
    "ageRangeYears": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["From ", _interpolate(_list(0)), " to ", _interpolate(_list(1)), " years"])}
  },
  "home": {
    "banners": {
      "a": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Friso AR"])},
        "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For reflux: with carob bean gum and hydrolysed protein"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/home/slides/1/packshot_en.png"])}
      },
      "b": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculators"])},
        "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With the WHO seal of confidence, I was now able to calculate the growth percentiles of babies instantly."])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/home/slides/2/device_en.png"])}
      }
    },
    "columns": {
      "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover our complete range of products and choose the right option for your baby."])},
      "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check out the latest scientific materials we have developed for you, so that you are always up to date on innovation and scientific advances."])},
      "textC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help parents, even from a distance, by sharing these educational materials."])}
    },
    "app": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our new application is finally here for you!"])},
      "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are very happy to announce that our new App is now available for download for iPhone or Android phones!"])},
      "imageAUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/home/app-devices_en.png"])},
      "imageBUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/home/qr-code_en.png"])}
    },
    "contact": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't have any more doubts..."])},
      "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write your email and we will contact you as soon as possible."])},
      "inputA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENTER YOUR E-MAIL ADDRESS"])},
      "inputB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WRITE YOUR QUESTION HERE"])},
      "buttonA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request contact"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent!"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred!"])},
      "sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending..."])}
    }
  },
  "login": {
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This website is intended exclusively for healthcare professionals."])},
    "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If your access is already granted, enter the details below.<br/>If you are a healthcare professional and do not have the credentials for this website,<br/><u>ask your Friso contact person</u>."])},
    "inpUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "inpPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "invalidCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid credentials!"])}
  },
  "portfolio": {
    "banner": {
      "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breastfeeding is the best food for the healthy growth and development of the infant."])},
      "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When breastfeeding is not an option, our product range presents solutions for every baby, with or without special needs."])}
    },
    "allProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All products"])},
    "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special"])},
    "regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular"])},
    "notice": {
      "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breastfeeding provides the best nutrition for healthy growth and development of infants. Mothers should receive guidance regarding proper maternal nutrition to help maintain adequate intake and quality breast milk. Unnecessary introduction of partial or complete formula feeding or other complementary foods or beverages can have a negative effect on breastfeeding, which may be irreversible. Mothers should consult their doctor and consider the social and economic implications before deciding to use breast milk substitutes or if they have difficulty breastfeeding. Instructions for use, preparation and storage of breast milk substitutes should be carefully followed as inappropriate or unnecessary use can be a health risk."])},
      "buttonA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have read and understand"])}
    }
  },
  "product": {
    "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingredients"])},
    "preparation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparation"])},
      "tableTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggested feeding chart"])},
      "table": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["<img class=\"table\" src=\"", _interpolate(_list(0)), "\"/><p><strong>1 level scoop is approx. ", _interpolate(_list(1)), " grams.<br/>Use of unboiled water, unsterilized cup or containers or improper preparation of the product may be harmful to your child.</strong></p>"])},
      "instructions": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructions for<br/>preparation and use"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your child's health depends on carefully following the instructions for preparation and use:"])},
        "number1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wash hands and utensils thoroughly using clean water."])},
        "number2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boil the water for 5 minutes and let it cool until lukewarm. If not available, puriﬁed water at room temperature may be used. Do not heat using a microwave oven."])},
        "number3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just before use, rinse the container and boil in water."])},
        "number4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour the indicated amount of water into the container."])},
        "number5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the spoon inside the can to add 1 level tablespoon per 30 ml of water."])},
        "number6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stir or shake well until the powder is completely dissolved."])},
        "number7": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Check the temperature and feed your baby when the formula ", _interpolate(_list(0)), " is warm."])},
        "number8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place the spoon in its original place and close the lid."])},
        "number9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the reconstituted formula within 1 hour, at the latest, after its preparation."])},
        "number10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discard any unconsumed intake."])},
        "warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Caution:<br/>Use formula ", _interpolate(_list(0)), " no later than one hour after preparation."])}
      }
    },
    "nutricionalTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutrition Information"])},
    "frisolacGold1": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso Gold 1"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso<sup>®</sup> Gold 1"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easily digestible protein"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From 0 to 6 months"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>No palm oil added to Friso Gold 1</li><li>Friso Gold 1 has LockNutri technology: minimal processing limits the glycation of protein, showing a positive effect on digestion</li><li>Literature has shown that prebiotic GOS stimulates the growth of beneficial bacteria and helps to maintain gut health</li><li>DHA and ARA, support learning and cognitive development</li><li>Contains nucleotides to support immunity</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demineralized whey, Skimmed milk, Vegetable oils (sunflower, low-alerope rapeseed, coconut), Lactose, Galacto-oligosaccharides, Maltodextrin, Fish oil, Choline chloride, Single cell oil (Mortierella alpina), Taurine, Lecithin (emulsifier) Minerals (Calcium Phosphate, Potassium Chloride, Calcium Carbonate, Sodium Chloride, Magnesium Citrate, Iron Sulfate, Calcium Hydroxide, Zinc Sulfate, Copper Sulfate, Manganese Sulfate, Potassium Iodide, Sodium Selenite),Vitamins (L-ascorbate sodium, Mixed tocopherols, L-ascorbyl-palmitate, DL-alpha-tocopherol acetate, Nicotinamide, Calcium D-pantothenate, Thiamine hydrochloride, Retinol acetate, Pyridoxine hydrochloride, Riboflavin, Folic acid, Phytomenadione, D-Biotin, nitric acid, Lycophenol hydrochloride??, Retinol acetate, Pyridoxine hydrochloride, Riboflavin, nitric acid Cholecalciferol, Nucleotides (Disodium inosine-5-monophosphate, Disodium uridine 5-monophosphate, Cytidine-5-monophosphoric acid, Adenosine-5-monophosphoric acid, Disodium guanosine-5-monophosphate)."])},
      "preparationTableUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/products/preparation-table-2_en.png"])}
    },
    "frisolacGold2": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso Gold 2"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso<sup>®</sup> Gold 2"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easily digestible protein"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From 6 to 12 months"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>No palm oil added to Friso Gold 2</li><li>Friso Gold 2 has LockNutri technology: minimal processing limits the glycation of protein, showing a positive effect on digestion</li><li>Literature has shown that prebiotic GOS stimulates the growth of beneficial bacteria and helps to maintain gut health</li><li>DHA and ARA, support learning and cognitive development</li><li>Contains nucleotides to support immunity</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skimmed milk, Demineralized whey, Vegetable oils (sunflower, low-alerope rapeseed, coconut), Lactose, Galacto-oligosaccharides, Maltodextrin, Fish oil, Unicellular oil (Mortierella alpina), Choline chloride, Taurine,Leucine (emulsion). Minerals: Calcium Phosphate, Potassium Chloride, Calcium Carbonate, Sodium Chloride, Magnesium Citrate Iron sulfate, Calcium hydroxide, Zinc sulfate, Copper sulfate, Manganese sulfate, Potassium iodide, Sodium Selenite), Vitamins (L-sodium ascorbate, Mixed tocopherols, DL-alpha-tocopherol acetate, L- ascorbyl palmitate, Nicotinamide, Calcium D-Pantothenate, Thiamine Hydrochloride, Pyridoxine Hydrochloride, Retinol acetate, Riboflavin, Folic acid, Fitomenadione, D-biotin, Cholecalciferol, Nucleotides (Disodium uridine-5-monophosphate, Cytidine-5-monophosphorous acid, Disodium inosine-5-monophosphate, Adenosine-5-monophosphorous acid, Disodium guanosine-5-monophosphate)."])},
      "preparationTableUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/products/preparation-table-4_en.png"])}
    },
    "frisolacGold3": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso Gold 3"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso<sup>®</sup> Gold 3"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easily digestible protein"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From 1 to 3 years"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>No palm oil added to Friso Gold 3</li><li>Friso Gold 3 has LockNutri technology: minimal processing limits the glycation of protein, showing a positive effect on digestion</li><li>Literature has shown that prebiotic GOS stimulates the growth of beneficial bacteria and helps to maintain gut health</li><li>Contains nucleotides to support immunity</li><li>DHA and ARA support learning and development</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skimmed milk, Glucose syrup solids, Vegetable oils (Sunflower, low-erucic rapeseed, coconut), Succrose, Galacto-oligosaccharides, Lactose, Fish oil, Soy Lecithin (emulsifier), Choline Chloride, Inozit, Taurine, Single cell oil, Minerals (Calcium carbonate, Ferrous sulfate, Zinc sulfate, Manganese sulfate, Copper sulfate, Sodium Selenate, Potassium Iodide), Vitamins (L-ascorbate sodium, Mixed tocopherols, L-Ascorbyl palmitate, DL-alpha-tocopherol acetate, Nicotinamide, Calcium D-pantothenate, Retinol-acetate, Riboflavin, β-carotene, Thiamine hydrochloride, Pyridoxine hydrochloride, Folic acid, Phytomenadione, Cholecalciferol, D-Biotin; Nucleotides (Disodium uridine-5'-monophosphate, Cytidine-5'-monophosphoric acid, Disodium inosine-5'-monophosphate, Adenosine-5'-monophosphoric acid, Disodium guanosine - 5'-monophosphate)."])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<img class=\"table\" src=\"/img/products/preparation-table-6_en.png\"/><p>The use of unboiled water, unsterilized cups or containers, or improper product preparation can be harmful to your child.</p><img class=\"table\" src=\"/img/products/preparation-table-7_en.png\"/>"])}
    },
    "frisoVom1": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso VOM 1"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso<sup>®</sup> VOM 1"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutritional management of vomiting, constipation and colic"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From 0 to 6 months"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Friso VOM 1 - Comfort has LockNutri: minimal processing limits the glycation of protein, showing a positive effect on digestion</li><li>Friso VOM 1 - Comfort with Carob Bean Gum (CBG) is effective in reducing the incidence of vomiting, constipation and colic</li><li>Carob bean gum has been shown to be effective for:<ul><li>Colics - CBG and GOS both stimulate the growth of beneficial intestinal microflora</li><li>Constipation - CBG helps to soften the stool and normalizes its frequency</li><li>Vomiting - CBG thickens the mixture, thereby reducing the volume and frequency of vomiting</li></ul></li><li>DHA and ARA support learning and development</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skimmed milk, Demineralized whey, Vegetable oils (palm, palm kernel, canola (low-erucic acid type), sunflower), Lactose, Carob bean gum, Galacto-oligosaccharides, Maltodextrin, Whey protein concentrate, Fish oil, Choline chloride, Single Oil (Mortierella alpina), Meso-inositol, Taurine, Lecithin (emulsifier); Minerals (Potassium citrate, Tri-calcium phosphate, Calcium carbonate, Sodium chloride, Magnesium citrate, Potassium chloride, Calcium hydroxide, Ferrous sulfate, Zinc sulfate, Cupric sulfate, Manganese sulfate, Potassium iodide, Sodium selenite), Vitamins (Sodium L-ascorbate, L-Ascorbyl palmitate, Mixed tocopherol concentrate, Calcium D-pantothenate, DL α-tocopherol acetate, Nicotinamide, Thiamine hydrochloride, Riboflavin, Retinyl-acetate, Pyridoxine hydrochloride, Folic acid, Phytomenadione, D-Biotin, Cholecalciferol), Nucleotides (Disodium uridine-5’-monophosphate, Cytidine-5’-monophosphate, Disodium inosine-5’-monophosphate, Adenosine-5’-monophosphate, Disodium guanosine-5’-monophosphate)."])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<img class=\"table\" src=\"/img/products/preparation-table-9_en.png\"/><p>1 levelled scoop is aprox. 4,3 grams<br/>100ml of ready Infant Formula ~ 13g of powder (3 levelled scoops) + 90ml of water</p><p>* Consult your pediatrician for feeding scheme for your baby</p>"])}
    },
    "frisoVom2": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso VOM 2"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso<sup>®</sup> VOM 2"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutritional management of vomiting, constipation and colic"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From 6 to 12 months"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Friso VOM 2 - Comfort has LockNutri: minimal processing limits the glycation of protein, showing a positive effect on digestion</li><li>Friso VOM 2 - Comfort with Carob Bean Gum (CBG) is effective in reducing the incidence of vomiting, constipation and colic</li><li>Carob bean gum has been shown to be effective for:<ul><li>Colics - CBG and GOS both stimulate the growth of beneficial intestinal microflora</li><li>Constipation - CBG helps to soften the stool and normalizes its frequency</li><li>Vomiting - CBG thickens the mixture, thereby reducing the volume and frequency of vomiting</li></ul></li><li>DHA and ARA support learning and development</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skimmed milk, Demineralized whey, Vegetable oils (palm, palm kernel, canola (low-erucic acid type), sunflower), Lactose, Carob bean gum, Galacto-oligosaccharides, Maltodextrin, Fish oil, Choline chloride, Single Oil (Mortierella alpina), Taurine, Lecithin (emulsifier)  Minerals (DiCalcium phosphate, Potassium citrate, Tri-calcium phosphate, Calcium carbonate, Sodium chloride, Magnesium citrate, Potassium chloride, Magnesium hydrogen phosphate, Ferrous sulfate, Calcium hydroxide, Zinc sulfate, Cupric sulfate, Manganese sulfate, Potassium iodide, Sodium selenite), Vitamins (Sodium L-ascorbate, L-Ascorbyl palmitate, Tocopherol concentrate, Calcium D-pantothenate, DL α-tocopherol acetate, Nicotinamide, Thiamin hydrochloride, Riboflavin, Retinyl-acetate, Pyridoxine hydrochloride, Folic acid, Phytomenadione, D-Biotin, Cholecalciferol, Nucleotides (Disodium uridine-5’-monophosphate, Cytidine-5’-monophosphate, Disodium inosine-5’-monophosphate, Adenosine-5’-monophosphoric acid, Disodium guanosine-5’-monophosphate)."])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<img class=\"table\" src=\"/img/products/preparation-table-10_en.png\"/><p>1 levelled scoop is aprox. 5,0 grams<br/>100ml of ready Infant Formula ~ 15g of powder (3 levelled scoops) + 90ml of water</p><p>* Consult your pediatrician for feeding scheme for your baby<br/>** After the age of 6 months your baby needs some extra's.</p>"])}
    },
    "frisolacPremature": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac Premature"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac<sup>®</sup> Premature"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For premature and low birthweight infants."])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From birth onwards till term age or weight"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>To provide high content of protein, energy, micronutrients and PUFAs as recommended by ESPGHAN for preterm infants</li><li>Frisolac Gold Prematuros has partially hydrolysed whey proteins and extensively hydrolysed casein proteins</li><li>PUFAs DHA, EPA, AA support visual and cognitive development</li><li>MCT fats support the energy needs</li><li>Prebiotic GOS is known from research to be beneficial for good gut microbiota</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milk solids, Lactose, Whey protein hydrolysate, Vegetable oils, Palm oil , Canola oil (low erucic acid type), Palm kernel oil, MCT oil, Sunflower oil, Maltodextrin, Casein hydrolysate, Emulsifier (Ctric acid esters of mono- and diglycerdides, Galacto-oligosaccharides, Calcium phosphate, Fish oil, Single cell oil, Calcium carbonate, Magnesium chloride, Sodium chloride, Sodium L-ascorbate, Meso-inositol, Choline chloride, Taurine, Ferrous sulphate, DL a-tocopheryl acetate, Zinc sulphate, Nicotinamide, Emulsifier (Sunflower lecithine), L-carnitine, Cytidine-5'-monophosphoric acid, L-Ascorbyl palmitate, Calcium D-pantothenate, Disodium urdine-5'-monophosphate, Adenosine-5'-monophosphoric acid, Disodium guanosine-5'-monophosphate, Retinyl-acetate, Disodium inosine-5'-monophosphate, Cupric sulphate, Riboflavin, Thiamin hydrochloride, Pyridoxine hydrochloride, Folic acid, Potassium iodide, Phytomenadione, Cholecalciferol, Sodium selenite, D-Biotin, Manganese sulphate, Cyanocobalamin"])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>The composition of Frisolac<sup>®</sup> Gold Premature Babies is based on a consumption of 150 ml of prepared formula per kg of weight per day.</p><img class=\"table\" src=\"/img/products/preparation-table-5_en.png\"/><p><strong>1 level scoop is approx. 5.2 grams.</strong></p>"])}
    },
    "frisolacGoldSinLactosa": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac Gold Sin Lactosa"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac<sup>®</sup> Gold Sin Lactosa"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For the management of lactose intolerance and severe diarrhoea in infants"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From 0 to 12 months"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Zinc reduces the severity and duration of diarrhoea and allows for better clearance of pathogens</li><li>Optimal iron content ensures the infants proper growth and development without the risk of prolonging infectious diarrhoea</li><li>Medium Chain Triglycerides (MCT) provide readily absorbable source of energy without additional stress to the gut</li><li>Starch: shown to reduce failure in re-hydration therapy</li><li>Inulin: To support the digestive system and stool formation</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glucose syrup, Vegetable oils, Palm oil, MCT oil, Palm kernel oil, Canola oil (low erucic acid type), Sunflower oil, Whey protein concentrate, Starch, Sodium caseinate, Fructo-oligosaccharides (inuline), Potassium chloride, Calcium phosphate, Choline bitartrate, Fish oil, Magnesium phosphate, Calcium carbonate, Sodium citrate, Potassium citrate, Single cell oil, Skimmed milk solids , Sodium L-ascorbate, Taurine, Meso-inositol, Emulsifier (Sunflower lecithine), Zinc sulphate, Ferrous sulphate, L-carnitine tartrate, L-Ascorbyl palmitate, Disodium urdine-5'-monophosphate, Calcium D-pantothenate, DL a-tocopheryl acetate, Nicotinamide, Cytidine-5'-monophosphoric acid, Antioxidant (Tocopherol-rich extract) , Disodium inosine-5'-monophosphate, Adenosine-5'-monophosphoric acid, Disodium guanosine-5'-monophosphate, Cupric sulphate, Riboflavin, Thiamin hydrochloride, Retinyl-acetate, Pyridoxine hydrochloride, Manganese sulphate, Potassium iodide, Folic acid, Phytomenadione, Sodium selenite, D-Biotin, Cholecalciferol, Cyanocobalamin"])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<img class=\"table\" src=\"/img/products/preparation-table-8_en.png\"/><p>1 levelled scoop is aprox. 4,3 grams<br/>100ml of ready Infant Formula ~ 13g of powder (3 levelled scoops) + 90ml of watter</p><p>* Consult your pediatrician for feeding scheme for your baby<br/>** After the age of 6 months your baby needs some extra's.<br/>*** After the age of 6 months you should add one extra scoop in 180ml of water.</p>"])}
    },
    "frisoPep": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso PEP"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso<sup>®</sup> PEP"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For the treatment of mild to moderate symptoms of cow’s milk protein allergy"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From 0 to 12 months"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Friso PEP has extensively hydrolysed whey proteins: around 37% of peptides contain &lt; 3 amino acids.</li><li>GOS for a healthy intestinal flora</li><li>DHA/AA for visual and cognitive development</li><li>Reduced level of lactose, to minimize protein contamination</li><li>With nucleotides for supporting immunity</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lactose, Glucose syrup, Vegetable oils (palm, palm kernel, canola (low erucic acid type, sunflower), Whey protein hydrolysate, Glucose syrup solids, Galacto-oligosaccharides, Citric acid of mono-and diglycerides (emulsifier), Choline chloride, Microalgae Oil (C. cohnii), Sintgle cell oil (Mortierella alpina), Meso-inositol, Taurine, Lecithin (Emulsifier), l-carnitine tartrate, Minerals: Calcium salts of ortophosphoric acid, Magnesium chloride, Potassium chloride, Sodium citrate, Sodium chloride, Potassium citrate, Ferrous sulfate, Zinc sulfate, Cupric sulfate, Manganese sulfate, Potassium iodide, Sodium selenite, Vitamins: Sodium L-ascorbate, Calcium D-pantothenate, L - Ascorbyl palmitate, Tocopherol- rich extract, DL-α-tocopherol acetate, Nicotinamide, Retinyl-acetate, Pyridoxine hydrochloride, Thiamine hydrochloride, Riboflavin, Folic acid, Phytomenadione, D-Biotin, Cholecalciferol, Cyancobalamin, Nucleotides (Disodium uridine-5’ - monophosphate, Cytidine-5'-monophosphoric acid, Disodium inosine-5'-monophosphate, Adenosine-5'- monophosphoric acid, Disodium guanosine-5 ' - monophosphate)."])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<img class=\"table\" src=\"/img/products/preparation-table-8_en.png\"/><p>1 levelled scoop is aprox. 4,3 grams<br/>100ml of ready Infant Formula ~ 13g of powder (3 levelled scoops) + 90ml of water</p><p>* Consult your pediatrician for feeding scheme for your baby<br/>** After the age of 6 months your baby needs some extra's.<br/>*** After the age of 6 months you should add one extra scoop in 180ml of water.</p>"])}
    },
    "frisoPepAc": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso PEP AC"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso<sup>®</sup> PEP AC"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For the treatment of severe symptoms of cow’s milk protein allergy"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From 0 to 12 months"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Friso PEP AC has extensively hydrolysed casein proteins: 60% of peptides contain &lt; 3 amino acids.</li><li>Friso PEP AC does not induce T-cell proliferation and proinflammatory cytokine release in vitro</li><li>Lactose free, to avoid any protein contamination</li><li>With nucleotides for supporting immunity</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glucose syrup, Vegetable oils (palm, palm kernel, canola (low erucic acid type, sunflower), Casein hydrolysate, Glucose syrup solids, Citric acid of mono-and diglycerides (emulsifier), Choline chloride, Microalgae Oil (C. cohnii), Sintgle cell oil (Mortierella alpina), Meso-inositol,  Taurine, Lecithin (Emulsifier), L-carnitine tartrate, Minerals: Calcium phosphate, Potassium chloride, Magnesium chloride, Calcium carbonate, Ferrous sulphate, Zinc sulfate, Cupric sulfate, Manganese sulphate, Potassium iodide, Sodium selenite, Vitamins: Sodium L-ascorbate, Calcium D-pantothenate, DL-α-tocopherol acetate, L - Ascorbyl palmitate, Mixed tocopherol concentrate, Nicotinamide, Rretinyl-acetate, Pyridoxine hydrochloride, Riboflavin,Thiamine hydrochloride, Folic acid, Phytomenadione, D-Biotin, Cholecalciferol, Cyancobalamin, Nucleotides: Disodium uridine-5’ - monophosphate, Cytidine-5'-monophosphoric acid, Disodium inosine-5'-monophosphate, Adenosine-5'- monophosphoric acid, Disodium guanosine-5 ' - monophosphate."])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<img class=\"table\" src=\"/img/products/preparation-table-8_en.png\"/><p>1 levelled scoop is aprox. 4,3 grams<br/>100ml of ready Infant Formula ~ 13g of powder (3 levelled scoops) + 90ml of water</p><p>* Consult your pediatrician for feeding scheme for your baby<br/>** After the age of 6 months your baby needs some extra's.<br/>*** After the age of 6 months you should add one extra scoop in 180ml of water.</p>"])}
    },
    "frisoHa1": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso HA 1"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso<sup>®</sup> HA 1"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To help reduce the risk of developing cow’s milk protein allergy (CMPA) for infants at risk, based on their family history"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From 0 to 6 months"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Friso Gold HA 1 has partially hydrolysed whey proteins known to prevent induction of sensitization from cow’s milk</li><li>GOS is known from research to be beneficial for a healthy intestinal flora</li><li>DHA/AA supports visual and cognitive development</li><li>Nucleotides stimulate development of the immune system</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lactose, Vegetable oils (palm, palm kernel, canola (low erucic acid type, sunflower), Whey protein hydrolysate, Maltodextrin, Galacto-oligosaccharides, Citric acid of mono-and diglycerides (emulsifier), Choline chloride, Fish oil, Single cell oil (Mortierella alpina), Meso-Inositol, Taurine, Lecithin (emulsifier), L-carnitine tartrate,  Minerals (Calcium salts of orthophosphoric acid, Magnesium chloride, Potassium chloride, Sodium chloride, Ferrous sulfate, Zinc sulfate, Cupric sulphate, Manganese sulphate, Potassium iodide, Sodium selenite), Vitamins (Sodium L-ascorbate, L-Ascorbyl palmitate, Calcium D-pantothenate, Tocopherol- rich extract, DL-α-tocopheryl acetate, Nicotinamide, Thiamin hydrochloride, Riboflavin, Retinyl-acetate, Pyridoxine hydrochloride, Folic acid, Phytomenadione, D-Biotin, Cholecalciferol, Cyancobalamin), Nucleotides (Disodium uridine-5’ - monophosphate, Cytidine-5'-monophosphoric acid, Disodium inosine-5'-monophosphate, Adenosine-5'- monophosphoric acid, Disodium guanosine-5 ' - monophosphate)."])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<img class=\"table\" src=\"/img/products/preparation-table-9_en.png\"/><p>1 levelled scoop is aprox. 4,3 grams<br/>100ml of ready Infant Formula ~ 13g of powder (3 levelled scoops) + 90ml of water</p><p>* Consult your pediatrician for feeding scheme for your baby</p>"])}
    },
    "frisoHa2": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso HA 2"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso<sup>®</sup> HA 2"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To help reduce the risk of developing cow’s milk protein allergy (CMPA) for infants at risk, based on their family history"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From 6 to 12 months"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Friso Gold HA 2 has partially hydrolysed whey proteins known to prevent induction of sensitization from cow’s milk</li><li>GOS is known from research to be beneficial for a healthy intestinal flora</li><li>DHA/AA supports visual and cognitive development</li><li>Nucleotides stimulate development of the immune system</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingredients: Lactose, Glucose syrup solids, Vegetable oils (palm oil, canola oil (low erucic acid type),  palm kernel oil, sunflower oil, Single cell oil (Mortierella alpina), Whey protein hydrolysate, Maltodextrin, Galacto-oligosaccharides, Citric acid esters of mono-and diglycerides ( Emulsifier), Choline bitartrate,  Fish oil, Choline chloride, Taurine, Meso-inositol, L-carnitine, Soy lecithin (emulsifier), Citric acid (acidity regulator), Minerals (Calcium phosphate, Potassium citrate,  Magnesium chloride, Magnesium phosphate, Sodium chloride,  Sodium citrate, Potassium hydroxide,  Ferrous sulphate, Zinc sulphate, Cupric sulphate,   Manganese sulphate,  Potassium iodide, Sodium selenite,  Vitamines (Sodium L-ascorbate, DL α -tocopheryl acetate, L-Ascorbyl palmitate, Nicotinamide, Calcium D-pantothenate, Riboflavin, Thiamin hydrochloride, Retinyl-acetate,  Pyridoxine hydrochloride,  Folic acid,  Phytomenadione,  D-Biotin, Cholecalciferol, Cyanocobalamin), Nucleotides (Cytidine-5'-monophosphoric acid, Adenosine-5'-monophosphoric acid, Disodium guanosine-5'-monophosphate, Disodium inosine-5'-monophosphate, Disodium uridine-5'-monophosphate)."])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<img class=\"table\" src=\"/img/products/preparation-table-10_en.png\"/><p>1 levelled scoop is aprox. 5,0 grams<br/>100ml of ready Infant Formula ~ 15g of powder (3 levelled scoops) + 90ml of water</p><p>* Consult your pediatrician for feeding scheme for your baby<br/>** After the age of 6 months your baby needs some extra's.</p>"])}
    },
    "frisolacGoldComfortAr": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac Gold Comfort AR"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac<sup>®</sup> Gold Comfort AR"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dietary management of gastroesophageal reflux."])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From 0 to 12 months"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Frisolac Gold Comfort AR contains the optimal amount of carob bean gum that has proven to be effective in reducing reflux rates</li><li>GOS prebiotics support healthy gut microbiota</li><li>Partially hydrolyzed whey protein accelerates the rate of gastric emptying.</li><li>DHA and ARA support learning and cognitive development</li><li>Full lactose: the optimal carbohydrate for infants</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milk solids, Skimmed milk, Demineralized whey, Lactose, Whey protein concentrate, Vegetable oils, Palm oil , Palm kernel oil, Canola oil (low erucic acid type), Sunflower oil, Whey protein hydrolysate, Carob bean gum, Maltodextrin, Galacto-oligosaccharides, Calcium phosphate, Emulsifier (Ctric acid esters of mono- and diglycerdides, Fish oil, Potassium citrate, Calcium carbonate, Choline chloride, Sodium chloride, Single cell oil, Magnesium citrate, Potassium chloride, Sodium L-ascorbate, Magnesium chloride, Meso-inositol, Taurine, Ferrous sulphate, Emulsifier (Sunflower lecithine), Calcium hydroxide, L-phenylalanine, L-tyrosine, Zinc sulphate, Disodium urdine-5'-monophosphate, L-Ascorbyl palmitate, Calcium D-pantothenate, Antioxidant (Tocopherol-rich extract) , Cytidine-5'-monophosphoric acid, DL a-tocopheryl acetate, Disodium inosine-5'-monophosphate, Nicotinamide, Adenosine-5'-monophosphoric acid, L-carnitine tartrate, Disodium guanosine-5'-monophosphate, Cupric sulphate, Thiamin hydrochloride, Riboflavin, Retinyl-acetate, Pyridoxine hydrochloride, Manganese sulphate, Folic acid, Potassium iodide, Phytomenadione, Sodium selenite, D-Biotin, Cholecalciferol, Cyanocobalamin. Contains: Milk, lactose and fish products."])},
      "preparationTableUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/products/preparation-table-1_en.png"])}
    }
  }
}