import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col h-screen justify-between" }
const _hoisted_2 = { class: "flex-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header = _resolveComponent("app-header")!
  const _component_app_footer = _resolveComponent("app-footer")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_app_header),
    _createElementVNode("main", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createVNode(_component_app_footer)
  ]))
}