import axios from "axios";
import {
  checkStorageAuthToken,
  setStorageAuthToken,
  removeStorageAuthToken
} from "./storage";

interface RequestError {
  code: number;
  message: string;
}

interface RequestResponse {
  success: boolean;
  errors: RequestError[];
}

export default {
  isUserAuthenticated: (): boolean => {
    return checkStorageAuthToken();
  },
  login: async (
    username: string,
    password: string
  ): Promise<RequestResponse | void> => {
    const baseUrl =
      "https://api.metacriacoes.com/friesland-campina/app-friso/v1/";

    const body = {
      action: "login",
      username: username,
      password: password
    };

    return new Promise((resolve, reject) => {
      axios
        .post(baseUrl, body)
        .then(res => {
          if (res.data.success) {
            setStorageAuthToken();
            resolve();
          } else {
            reject({
              success: false,
              errors: [{ code: 11, message: "Datos incorrectos!" }]
            });
          }
        })
        .catch(err => {
          reject({
            success: false,
            errors: [{ code: 1, message: err.message }]
          });
        });
    });
  },
  logout: (): void => {
    removeStorageAuthToken();
  }
};
