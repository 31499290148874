
import { defineComponent } from "vue";
import { i18n } from "@/i18n";

export default defineComponent({
  name: "Footer",
  setup() {
    const currentYear = new Date().getFullYear();
    return {
      currentYear,
      t: i18n.global.t
    };
  }
});
